import React from 'react';
import styles from './styles.module.scss';

const TariffCard = props => {
  return (
    <div className={styles.tariffHolder}>
      <div className={styles.card}>
        <div className={styles.title}>{I18n.frontend.components.order.tariff_card.packet} "{props.title}"</div>
        <div className={styles.priceHolder}>{props.price} ₽</div>
        <div className={styles.tooltip}>
          <ul className={styles.description}>
            {props.descriptions &&
              props.descriptions.map(item => {
                return (
                  <li className={styles.descriptionItems} key={item}>
                    {item}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <div className={styles.counterControlWrapper}>
        <div className={styles.counterControlMinus} onClick={() => props.updateCount(props.count - 1)} />
        <input
          className={styles.counter}
          onChange={event => props.updateCount(event.target.value)}
          value={props.count}
          type="number"
          readOnly
          id={props.inputId}
          name={props.inputName}
        />
        <div className={styles.counterControlPlus} onClick={() => props.updateCount(props.count + 1)} />
      </div>
    </div>
  );
};

export default TariffCard;
