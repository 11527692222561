import React from 'react';
import styles from './styles.module.scss';
import ImagePreview from '../imagePreview';

class FileInput extends React.Component {
  state = {
    files: null,
    base64: null,
  };

  onChange = ({ currentTarget }) => {
    this.getBase64Image(currentTarget.files[0]);
    this.setState({
      files: currentTarget.files,
    });
  };

  onDelete = e => {
    const { setImage } = this.props;
    if (this.fileInput) this.fileInput.value = '';
    setImage && setImage({ base64: null, name: null, url: null, type: null });
    this.setState({
      files: null,
    });
  };

  onAddFile = () => {
    this.fileInput && this.fileInput.click();
  };

  getBase64Image = file => {
    const { setImage } = this.props;
    let reader = new FileReader(),
      img = new Image();
    reader.onloadend = () => {
      if (file.type.split('/')[0] === 'image') {
        img.src = reader.result;
        img.onload = () => {
          setImage &&
            setImage({
              base64: reader.result,
              name: file.name,
              type: file.type,
              imageWidth: img.naturalWidth || img.width,
              imageHeight: img.naturalHeight || img.height,
              size: file.size,
            });
          this.setState({ base64: reader.result });
        };
      }
      setImage &&
        setImage({
          base64: reader.result,
          name: file.name,
          type: file.type,
          size: file.size,
        });
      this.setState({ base64: reader.result });
    };
    reader.readAsDataURL(file);
  };

  handleClick = () => {
    const { disabled, image } = this.props;
    if (disabled) return;
    image ? this.onDelete() : this.onAddFile();
  };

  render() {
    const { files } = this.state;
    const { name, image, url, isShownPreview } = this.props;
    return (
      <React.Fragment>
        <input type="file" ref={el => (this.fileInput = el)} hidden onChange={this.onChange} />
        <div className={styles.loadPhoto}>
          <div onClick={this.handleClick} className={styles.button}>
            <div className={`${styles.counterControlPlus} ${image ? styles.delete : ''}`} />
            <div className={styles.loadPhotoLabel}>{image ? this.props.clearLabel : this.props.label}</div>
          </div>
          {name && (<a href={url} target="_blank" className={styles.loadPhotoLink}>
            {name}
          </a>)}
        </div>
      </React.Fragment>
    );
  }
}

export default FileInput;
