import React from 'react';
import TariffCard from '../tariffCard';
import styles from './styles.module.scss';

class TariffControl extends React.Component {
  constructor(props) {
    super(props);
    const { tariffs } = props;
    this.state = {
      tariffs: {
        ...tariffs.reduce(
          (res, item) => ({
            ...res,
            [item.id]: {
              count: item.count || 0,
              price: item.price,
              title: item.title,
              descriptions: item.descriptions,
            },
          }),
          {},
        ),
      },
    };
  }

  updateCount = tariff => count => {
    if (count < 0) return;
    this.setState(prevState => ({
      tariffs: { ...prevState.tariffs, [tariff]: { ...prevState.tariffs[tariff], count } },
    }));
  };

  getPersonCount = () => {
    const { tariffs } = this.state;
    return Object.keys(tariffs).reduce((res, tariff) => (res = res + Number(tariffs[tariff].count)), 0);
  };

  getPrice = price => {
    return price || 0;
  };

  getResultPrice = () => {
    const { tariffs } = this.state;
    return Object.keys(tariffs).reduce(
      (res, tariff) => (res = res + Number(this.getPrice(tariffs[tariff].price)) * Number(tariffs[tariff].count)),
      0,
    );
  };

  render() {
    const { tariffs, errorText, isShownTariffError } = this.props;
    const t = I18n.frontend.components.order.tariff_control;
    return (
      <React.Fragment>
        <div className="form-row">
          <div className="form-label">{t.participation_form}</div>
          <div className="form-element">
            <div className={styles.tariffsHolder}>
              {tariffs &&
                tariffs.map(tariff => (
                  <TariffCard
                    title={tariff.title}
                    price={tariff.price}
                    count={this.state.tariffs[tariff.id].count}
                    updateCount={this.updateCount(tariff.id)}
                    descriptions={tariff.descriptions}
                    key={tariff.id}
                    inputId={tariff.inputId}
                    inputName={tariff.inputName}
                  />
                ))}
            </div>
            {isShownTariffError && <div className="invalid-feedback">{errorText}</div>}
          </div>
        </div>
        <div className={`${styles.formRow} form-row`}>
          <div className={`${styles.formLabel} form-label`}>{t.total_participants}</div>
          <div className={`${styles.formElement} form-element`}>
            <span>{this.getPersonCount()} {t.humans}</span>
          </div>
        </div>
        <div className={`${styles.formRow} form-row`}>
          <div className={`${styles.formLabel} form-label`}>{t.result_price}</div>
          <div className={`${styles.formElement} form-element`}>
            <span>{this.getResultPrice()} ₽</span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TariffControl;
