import React from 'react';
import styles from './styles.module.scss';
import FileInput from '../../shared/fileInput';
import 'url-polyfill';

const PhotoBar = props => {
  const t = I18n.frontend.components.registration.photo_bar;
  return (
    <div className={styles.photoInfo}>
      <div
        className={styles.photoHolder}
        style={
          props.image && {
            backgroundImage: "url('" + new URL(props.image) + "')",
            backgroundSize: 'contain',
          }
        }
      >
        {!props.photo.isValid && (
          <div className={`${styles.informCloud} inform-cloud error`}>{t.errors.invalid_file_format}</div>
        )}
      </div>
      <div className={styles.loadPhotoWrapper}>
        <FileInput
          disabled={props.disabled}
          label={t.add_photo}
          image={props.image}
          type={props.type}
          name={props.name}
          clearLabel={t.remove_photo}
          setImage={props.setImage}
        />
      </div>
      <ul className={styles.list}>
        {t.rules.map(item => (
          <li key={item} className={styles.listItem}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PhotoBar;
