const isDimensionsValid = ({ imageWidth: width, imageHeight: height }) => width >= 300 && height >= 400;
const isSizeValid = ({ size }) => size < 2 * 1024 * 1024;
const isPhotoTypeValid = ({ type }) =>
  ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/bmp'].some(item => item === type);
const isScanTypeValid = ({ type }) =>
  ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/bmp', 'application/pdf'].some(item => item === type);
const isPassportSeriestValid = passport_series => passport_series && passport_series.length === 4;
const isPassportNumberValid = passport_number => passport_number && passport_number.length === 6;

export default {
  isDimensionsValid,
  isSizeValid,
  isPhotoTypeValid,
  isScanTypeValid,
  isPassportSeriestValid,
  isPassportNumberValid,
};
