import React from 'react';
import styles from './styles.module.scss';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from './createAutoCorrectedDatePipe';

class DateInput extends React.Component {
  state = {
    day: '',
    month: '',
    year: '',
  };

  componentDidUpdate(prevProps) {
    const { date } = this.props;
    if (prevProps.date !== date) {
      const separateValues = date ? date.split('.') : ['', '', ''];
      this.setState({ day: separateValues[0], month: separateValues[1], year: separateValues[2] });
    }
  }

  onUpdateDate = key => e => {
    this.setState({ [key]: e.currentTarget.value }, () => {
      const { day, month, year } = this.state;
      this.props.onChange('birthday')(`${day}.${month}.${year}`);
    });
  };

  render() {
    const { day, month, year } = this.state;
    return (
      <div className={styles.holder}>
        <MaskedInput
          className={`${styles.input} ${styles.day}`}
          placeholder="21"
          mask={[/\d/, /\d/]}
          value={day}
          onChange={this.onUpdateDate('day')}
          guide={false}
          pipe={createAutoCorrectedDatePipe('dd')}
        />
        <MaskedInput
          className={`${styles.input} ${styles.month}`}
          placeholder="01"
          mask={[/\d/, /\d/]}
          value={month}
          onChange={this.onUpdateDate('month')}
          guide={false}
          pipe={createAutoCorrectedDatePipe('mm')}
        />
        <MaskedInput
          className={`${styles.input} ${styles.year}`}
          placeholder="1965"
          mask={[/\d/, /\d/, /\d/, /\d/]}
          value={year}
          onChange={this.onUpdateDate('year')}
          guide={false}
          pipe={createAutoCorrectedDatePipe('yyyy')}
        />
      </div>
    );
  }
}

export default DateInput;
