import React, {useState, useEffect} from 'react';
import ReactSelect from 'react-select';

const customStyles = {
  control: (provided, state) => {
    const styles = state.selectProps.menuIsOpen
      ? {
          border: '1px solid #D4D4D4',
          borderBottomColor: 'transparent',
          borderRadius: '0.5rem 0.5rem 0 0',
        }
      : {
          border: '1px solid #D4D4D4',
          borderRadius: '0.5rem',
        };
    return {
      height: '4.6rem',
      padding: '0 1.4rem',
      ...styles,
      background: '#fff',
      outline: '0',
      display: 'flex',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: () => ({
    top: '100%',
    backgroundColor: 'hsl(0,0%,100%)',
    borderRadius: '0 0 0.5rem 0.5rem',
    border: '1px solid #D4D4D4',
    borderTop: 0,
    marginBottom: '8px',
    position: 'absolute',
    width: '100%',
    zIndex: '1',
    boxSizing: 'border-box',
  }),
  option: () => ({
    padding: '0.3rem 1.4rem',
  }),
  dropdownIndicator: (provided, state) => {
    const transform = state.selectProps.menuIsOpen ? 'rotate(-90deg)' : 'rotate(0)';
    const transition = 'transform 300ms';

    return {
      display: 'flex',
      transition: 'color 150ms',
      boxSizing: 'border-box',
      color: '#303030',
      transform,
      transition,
    };
  },
  valueContainer: (provided, state) => ({
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    padding: 0,
    borderBottom: state.selectProps.menuIsOpen ? '1px solid #D4D4D4' : '1px solid transparent',
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',
  }),
  container: () => ({
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
  }),
  placeholder: () => ({
    color: '#D4D4D4',
  }),
};

const Select = props =>  {
  const { options, id, name, disabled } = props;
  const t = I18n.frontend.components.shared.select;
    const option = props.value || (props.options && props.options.find(item => item.default));
  const [value, setValue] = useState(option);

  const onChange = value => {
    props.onChange && props.onChange(value);
    setValue(value);
  }

  useEffect(()=>{
    setValue(props.value);
  },[props.value]);


  return (
    <React.Fragment>
      <input hidden name={name} id={id} value={value ? value.value : ''} readOnly />
      <ReactSelect
        value={value}
        placeholder={t.placeholder}
        options={options}
        styles={customStyles}
        maxMenuHeight={150}
        onChange={onChange}
        isDisabled={disabled}
      />
    </React.Fragment>
  );
}

export default Select;
