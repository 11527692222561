import React from 'react';
import TextInput from '../../shared/input';
import Select from '../../shared/select';
import PhotoBar from '../photoBar';
import styles from './styles.module.scss';
import PassportInput from '../../shared/passportInput';
import DateInput from '../../shared/dateInput';
import FileInput from '../../shared/fileInput';
import emailMask from '../../../helpers/emailMask';
import RU_PHONE_MASK from './ruPhoneMask';

const Form = props => {
  const t = I18n.frontend.components.registration.form;
  const isForeigner = props.countries.find(el => el.value === props.values.nationality_id) &&
  props.countries.find(el => el.value === props.values.nationality_id).slug !== 'russia';
  return (
    <form>
      <fieldset disabled={!props.canEditable}>
        <div className="wrapper">
          <div className="sidebar">
            {props.tariffLabel}
            <PhotoBar
              image={props.values.photo}
              name={props.values.photo_original_filename}
              type={props.values.photo_content_type}
              setImage={props.setImage('photo')}
              photo={props.photo}
              disabled={!props.canEditable}
            />
          </div>
          <div className="center">
            <div className="form-row">
              <label className="form-label">{t.country}</label>
              <div className="form-element">
                <Select
                  value={props.countries.find(el => el.value === props.values.country_id)}
                  options={props.countries}
                  onChange={props.onChangeSelector('country_id')}
                  disabled={!props.canEditable}
                />
              </div>
              {props.errors.country && <div className="invalid-feedback">{props.errors.country}</div>}
            </div>
            <div className="form-row">
              <label className="form-label">{t.nationality}</label>
              <div className="form-element">
                <Select
                  value={props.countries.find(el => el.value === props.values.nationality_id)}
                  options={props.countries}
                  onChange={props.onChangeSelector('nationality_id')}
                  disabled={!props.canEditable}
                />
              </div>
              {props.errors.nationality && <div className="invalid-feedback">{props.errors.nationality}</div>}
            </div>
            <TextInput
              label={t.surname}
              placeholder={t.surname_placeholder}
              value={props.values.surname}
              onChange={props.onChangeSurname}
              error={props.errors.surname}
            />
            <TextInput
              label={t.surname_lat}
              placeholder={t.surname_lat_placeholder}
              value={props.values.surname_latin}
              onChange={props.onChangeInput('surname_latin')}
            />
            <TextInput
              label={t.name}
              placeholder={t.name_placeholder}
              value={props.values.name}
              onChange={props.onChangeName}
              error={props.errors.name}
            />
            <TextInput label={t.name_lat} placeholder={t.name_lat_placeholder} value={props.values.name_latin} onChange={props.onChangeInput('name_latin')} />
            {!isForeigner && <TextInput
              label={t.patronymic}
              placeholder={t.patronymic_placeholder}
              value={props.values.patronymic}
              onChange={props.onChangePatronymic}
              error={props.errors.patronymic}
            />}
            <div className="form-row">
              <label className="form-label">{t.gender.title}</label>
              <div className="form-element">
                <div className={`${styles.radioWrapper} form-check`}>
                  <input
                    className="form-bool-input"
                    type="radio"
                    id="male"
                    name="gender"
                    checked={props.values.gender === 'male'}
                    onChange={() => props.onChangeField('gender')('male')}
                  />
                  <label className="form-label" htmlFor="male">
                    {t.gender.male}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-bool-input"
                    type="radio"
                    id="female"
                    name="gender"
                    checked={props.values.gender === 'female'}
                    onChange={() => props.onChangeField('gender')('female')}
                  />
                  <label className="form-label" htmlFor="female">
                    {t.gender.female}
                  </label>
                </div>
              </div>
              {props.errors.gender && <div className="invalid-feedback">{props.errors.gender}</div>}
            </div>
            <div className="form-row">
              <label className="form-label">{t.birthday}</label>
              <div className="form-element">
                <DateInput date={props.values.birthday} onChange={props.onChangeField} />
              </div>
              {props.errors.birthday && <div className="invalid-feedback">{props.errors.birthday}</div>}
            </div>
            <TextInput
              label={t.birthplace}
              placeholder={t.birthplace_placeholder}
              value={props.values.birthplace}
              onChange={props.onChangeInput('birthplace')}
              error={props.errors.birthplace}
            />
            <div className="form-separator no-left" />
            {isForeigner ? (
              <TextInput
                label={t.passport.title}
                placeholder={t.passport.number}
                value={props.values.passport_number}
                onChange={props.onChangeInput('passport_number')}
              />
            ) : (
              <div className="form-row">
                <label className="form-label">{t.passport.title}</label>
                <div className="form-element">
                  <PassportInput
                    passport_series={props.values.passport_series}
                    passport_number={props.values.passport_number}
                    onChange={props.onChangeInput}
                    validatePassport={props.validatePassport}
                  />
                </div>
                {(props.errors.passport_series || props.errors.passport_number || !props.isPassportValid) && (
                  <div className="invalid-feedback">
                    {props.errors.passport_series || props.errors.passport_number || t.errors.passport.invalid_doc_format}
                  </div>
                )}
              </div>
            )}
            <div className="form-row">
              <label className="form-label">{t.passport.add_scan}</label>
              <div>
                <div
                    className={styles.photoHolder}
                    style={
                      props.values.passport_scan && {
                        backgroundImage: "url('" + new URL(props.values.passport_scan) + "')",
                        backgroundSize: 'contain',
                      }
                    }
                >
                  {!props.photo.isValid && (
                      <div className={`${styles.informCloud} inform-cloud error`}>{t.errors.invalid_file_format}</div>
                  )}
                </div>
                <div className={styles.loadPhotoWrapper}>
                  <FileInput
                        label={t.passport.add_scan}
                        clearLabel={t.passport.remove_scan}
                        disabled={!props.canEditable}
                        image={props.values.passport_scan}
                        url={props.values.passport_scan_url}
                        name={props.values.passport_scan_original_filename}
                        type={props.values.passport_scan_content_type}
                        setImage={props.setImage('passport_scan')}
                        isShownPreview
                  />
                </div>
              </div>
              {!props.passport_scan.isValid && <div className="invalid-feedback">{t.errors.passport.invalid_file_format}</div>}
            </div>
            <div className="form-row">
              <div className="form-label" />
              <div className="form-element">
                <ul className={styles.list}>
                  {[t.passport.second_page, t.passport.file_formats].map(
                    item => (
                      <li key={item} className={styles.listItem}>
                        {item}
                      </li>
                    ),
                  )}
                </ul>
              </div>
            </div>
            <div className="form-separator no-left" />
            <TextInput
              label={t.organization.title}
              placeholder={t.organization.title_placeholder}
              value={props.values.organization_name}
              onChange={props.onChangeInput('organization_name')}
              error={props.errors.organization_name}
            />
            <TextInput
              label={t.organization.position}
              placeholder={t.organization.position_placeholder}
              value={props.values.position}
              onChange={props.onChangeInput('position')}
              error={props.errors.position}
            />
            <TextInput
              label={t.email}
              placeholder={t.email.placeholder}
              value={props.values.email}
              onChange={props.onChangeInput('email')}
              mask={emailMask}
              error={props.errors.email}
            />
            <TextInput
              label={t.phone}
              placeholder={isForeigner ? '+429999999999' : '+7 (906) 555-55-55'}
              mask={isForeigner ?  undefined : RU_PHONE_MASK}
              value={props.values.phone_number}
              onChange={isForeigner ?  props.onChangePhone : props.onChangeInput('phone_number')}
              error={props.errors.phone_number}
            />
            <div className="form-separator no-left" />
            {Object.keys(props.values).find(item => item === 'car_model') && (
              <TextInput
                label={t.car_model}
                placeholder={t.car_model_placeholder}
                value={props.values.car_model}
                onChange={props.onChangeInput('car_model')}
              />
            )}
            {Object.keys(props.values).find(item => item === 'car_number') && (
              <TextInput
                label={t.car_number}
                placeholder={t.car_number_placeholder}
                value={props.values.car_number}
                onChange={props.onChangeInput('car_number')}
              />
            )}
            {Object.keys(props.values).find(item => item === 'car_model' || item === 'car_number') && (
              <div className="form-separator" />
            )}
            <fieldset className="form-row">
              <div className="form-check">
                <input
                  className="form-bool-input"
                  id="agreement"
                  type="checkbox"
                  checked={!!props.values.agree_to_processing}
                  onChange={props.onChangeProcessingAgreement}
                />
                <label className="form-label" htmlFor="agreement">
                  {t.agreenment}
                </label>
              </div>
              {props.errors.agree_to_processing && (
                <div className="invalid-feedback">{props.errors.agree_to_processing}</div>
              )}
            </fieldset>
            {props.canEditable && (
              <div className="form-row">
                <div className="button-holder">
                  <input
                    type="submit"
                    value={t.actions.submit}
                    disabled={
                      !(
                        props.photo.isValid &&
                        props.passport_scan.isValid &&
                        props.values.photo &&
                        props.values.passport_scan &&
                        props.isPassportValid &&
                        props.isBirthdayValid
                      )
                    }
                    onClick={props.updateInfo}
                    className="btn button primary centered"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default Form;
