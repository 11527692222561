import React from 'react';
import styles from './styles.module.scss';

class Modal extends React.Component {
  state = {
    isShownModal: !!this.props.text,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.setState({ isShownModal: !!this.props.text });
    }
  }

  setModalVisibility = option => e => {
    const { href } = this.props;
    !href && e.preventDefault();
    this.setState({
      isShownModal: option,
    });
  };

  render() {
    const { isShownModal } = this.state;
    const { text, href } = this.props;
    return (
      isShownModal && (
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div>{text}</div>
            <a className={styles.button} href={href} onClick={this.setModalVisibility(false)}>
              ОК
            </a>
          </div>
        </div>
      )
    );
  }
}

export default Modal;
