import React from 'react';
import styles from './styles.module.scss';

const ArrowBack = props => {
  return (
    <a href={props.href} className={styles.link}>
      <i className={styles.icon} />
      <span className={styles.label}>{I18n.frontend.components.shared.arrow_back.title}</span>
    </a>
  );
};

export default ArrowBack;
