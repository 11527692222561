import React from 'react';
import styles from './styles.module.scss';

export const Loader = () => {
  return (
    <div className={styles.preloader}>
      <div className={styles.loader}>
        <div className={styles.spinner} />
      </div>
    </div>
  );
};
