import React from 'react';
import MaskedInput from 'react-text-mask';

const TextInput = props => {
  return (
    <div className="form-row">
      {props.label && <label className="form-label">{props.label}</label>}
      {props.mask ? (
        <MaskedInput
          className="form-element styled"
          placeholder={props.placeholder}
          value={props.value || ''}
          onChange={props.onChange}
          mask={props.mask}
          disabled={props.disabled}
          guide={false}
          readOnly={props.readOnly}
        />
      ) : (
        <input
          className="form-element styled"
          placeholder={props.placeholder}
          value={props.value || ''}
          onChange={props.onChange}
          disabled={props.disabled}
          readOnly={props.readOnly}
        />
      )}
      {props.error && <div className="invalid-feedback">{props.error}</div>}
    </div>
  );
};

export default TextInput;
