const getMaskedValue = (mask, value) => {
  let index = 0;
  let getValueByMask = (element) => {
    if(value[index].match(element)) {
      const result = value[index];
      ++index;
      return result;
    }
    ++index;
    return getValueByMask(element);
  }
  let maskedValue = mask.reduce((res, element) => {
    if(typeof element === 'string') {
      if(element === value[index]) ++index;
      return res += element
    };
    return res += getValueByMask(element);
  },'');
  return maskedValue;
}

export default getMaskedValue;