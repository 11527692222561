import React from 'react';
import Form from './form';
import { cyrillicToTranslit, latinToTranslit } from './translit';
import Validation from './validation';
import Modal from '../../shared/modal';
import { Loader } from '../../shared/loader';
import capitalize from '../../../helpers/capitalize';
import locale from '../../../helpers/locale';
import getMaskedValue from '../../../helpers/getMaskValue';
import RU_PHONE_MASK from './ruPhoneMask';

const getTransformedFIO = str => {
  return capitalize(locale.isRu() ? str.replace(/[^а-яё \-\—]/ig,'') : str.replace(/[^a-z \-\—]/ig,''));
}

const isValidBirthday = (date) => {
  return date && date.match(/\d{1,2}.\d{1,2}.\d{4}/g);
}

class RegistrationForm extends React.Component {
  state = {
    form: {},
    errors: {},
    isProccessing: false,
    photo: {
      isValid: true,
    },
    passport_scan: {
      isValid: true,
    },
    passportValidation: {
      passport_series: true,
      passport_number: true,
    },
    successText: null,
    isBirthdayValid: false,
  };
  t = I18n.frontend.components.registration;

  componentDidMount() {
    this.loadInfo();
    this.countries = this.getCountries();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.isForeigner(prevState.form) !== this.isForeigner(this.state.form)) {
      this.setState(state=>({
        form: {...state.form, phone_number: state.form.phone_number && (this.isForeigner(this.state.form) ? state.form.phone_number.replace(/[^+0-9]/g,'') : getMaskedValue(RU_PHONE_MASK, state.form.phone_number))}
      }));
    }
  }

  isForeigner = (form) => this.countries.find(el => el.value === form.nationality_id) && this.countries.find(el => el.value === form.nationality_id).slug !== 'russia';

  loadInfo = () => {
    const { member_url } = this.props;
    this.setState({ isProccessing: true }, () =>
      fetch(member_url, { method: 'get', headers: { 'Content-Type': 'application/json' } })
        .then(resp => resp.json())
        .then(data => this.setState({ form: data, isBirthdayValid: isValidBirthday(data.birthday), isProccessing: false }))
        .catch(e => this.setState({ isProccessing: false })),
    );
  };

  updateErrors = (errors = {}) => {
    this.setState({ errors: errors });
  };

  getCountries = () => {
    return JSON.parse(this.props.countries).reduce(
      (res, item) => [...res, { label: item.name, value: item.id, slug: item.slug }],
      [],
    );
  };

  updateInfo = e => {
    e.preventDefault();
    const {
      form: { photo_url, passport_scan_url, ...sending_data },
    } = this.state;
    const { member_url } = this.props;
    const body = JSON.stringify({ order_profile: sending_data });
    this.setState({ isProccessing: true }, () => {
      fetch(member_url, {
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content'),
        },
        body,
      })
        .then(resp => resp.json())
        .then(data => {
          if (data.code === 'error') {
            return this.setState(
              {
                errors: Object.keys(data.message).reduce(
                  (res, item) => ({ ...res, [item]: data.message[item][0] }),
                  {},
                ),
                isProccessing: false,
              },
              () => {
                let errorBlock = $('.invalid-feedback')[0];
                errorBlock && $('html, body').animate({ scrollTop: $(errorBlock).offset().top - 110 }, 300);
              },
            );
          }
          return this.setState({
            errors: {},
            successText: I18n.frontend.components.registration.succeed,
            isProccessing: false,
          });
        })
        .catch(e => this.setState({ isProccessing: false }));
    });
  };

  onChangeInput = key => ({ currentTarget }) =>
    this.setState(prevState => ({ form: { ...prevState.form, [key]: currentTarget.value } }));

  onChangeField = key => value => this.setState(prevState => {
    if(key === 'birthday') {
      return { form: { ...prevState.form, [key]: value }, isBirthdayValid: isValidBirthday(value) };
    }
    return { form: { ...prevState.form, [key]: value } };
});

  onChangeSurname = ({ currentTarget }) => {
    const transformedValue = getTransformedFIO(currentTarget.value);
    const translator = locale.isRu() ? cyrillicToTranslit() : latinToTranslit();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        surname: transformedValue,
        surname_latin: translator.transform(transformedValue),
      },
    }))
  };

  onChangeName = ({ currentTarget }) => {
    const transformedValue = getTransformedFIO(currentTarget.value);
    const translator = locale.isRu() ? cyrillicToTranslit() : latinToTranslit();
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        name: transformedValue,
        name_latin: translator.transform(transformedValue),
      },
    }));
  };

  onChangePatronymic = ({currentTarget}) => {
    const transformedValue = getTransformedFIO(currentTarget.value);
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        patronymic: transformedValue,
      },
    }));
  }

  onChangePhone = ({currentTarget}) => {
    let value = (currentTarget.value.match(/\+?\d*/)||[])[0];
    value = value ? (value.includes('+') ? value : '+' + value) : value;
    value = value.substr(0, 20); // max 20 symbols
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        phone_number: value,
      },
    }));
  };

  onChangeInputWithCapitalization = key => ({ currentTarget }) =>
  this.setState(prevState => ({ form: { ...prevState.form, [key]: capitalize(currentTarget.value) } }));

  onChangeProcessingAgreement = () =>
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        agree_to_processing: !prevState.form.agree_to_processing,
      },
    }));

  onChangeSelector = formKey => item =>
    this.setState(prevState => ({ form: { ...prevState.form, [formKey]: item.value } }));

  setImage = formKey => image =>
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [formKey]: image.base64,
        [`${formKey}_content_type`]: image.type,
        [`${formKey}_original_filename`]: image.name,
        [`${formKey}_url`]: image.url,
      },
      [formKey]: { ...image, isValid: this.validateImage(formKey, image) },
    }));

  validateImage = (type, image) => {
    if (type === 'photo') {
      return Validation.isDimensionsValid(image) && Validation.isSizeValid(image) && Validation.isPhotoTypeValid(image);
    }
    return Validation.isScanTypeValid(image);
  };

  getTariffBlock = () => {
    const { form } = this.state;
    const { show_tariff_info } = this.props;
    if (!show_tariff_info) return null;
    switch (form.category) {
      case 'premium':
        return <div className="tariff-label">{this.t.packet.premium}</div>;
      case 'standard':
        return <div className="tariff-label">{this.t.packet.standard}</div>;
      case 'premium_with_car':
        return <div className="tariff-label">{this.t.packet.premium_with_car}</div>;
    }
  };

  validatePassport = (key, validator) => value =>
    this.setState(prevState => ({ passportValidation: { ...prevState.passportValidation, [key]: validator(value) } }));

  render() {
    const { form, errors, photo, passport_scan, passportValidation, successText, isProccessing, isBirthdayValid } = this.state;
    const countries = this.getCountries();
    return (
      <React.Fragment>
        {isProccessing && <Loader />}
        <Form
          values={form}
          tariffLabel={this.getTariffBlock()}
          onChangeInput={this.onChangeInput}
          onChangeInputWithCapitalization={this.onChangeInputWithCapitalization}
          onChangeSelector={this.onChangeSelector}
          updateInfo={this.updateInfo}
          countries={countries}
          onChangeName={this.onChangeName}
          onChangeSurname={this.onChangeSurname}
          onChangePatronymic={this.onChangePatronymic}
          onChangeProcessingAgreement={this.onChangeProcessingAgreement}
          onChangePhone={this.onChangePhone}
          setImage={this.setImage}
          onChangeField={this.onChangeField}
          errors={errors}
          photo={photo}
          passport_scan={passport_scan}
          validatePassport={this.validatePassport}
          isPassportValid={
            countries.find(el => el.value === form.nationality_id) &&
            countries.find(el => el.value === form.nationality_id).slug !== 'russia'
              ? true
              : passportValidation.passport_series && passportValidation.passport_number
          }
          canEditable={this.props.can_editable}
          isBirthdayValid={isBirthdayValid}
        />
        <Modal text={successText} href={`/${I18n.frontend.current_locale}/account`} />
      </React.Fragment>
    );
  }
}

export default RegistrationForm;
