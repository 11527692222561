import React from 'react';
import styles from './styles.module.scss';
import 'url-polyfill';

class ImagePreview extends React.Component {
  state = {
    isShown: false,
  };

  togglePreviewModal = option => e => {
    e.preventDefault();
    this.setState({ isShown: option });
  };

  render() {
    const { image, isShownPreview } = this.props;
    const { isShown } = this.state;
    return image && isShownPreview ? (
      <React.Fragment>
        <div
          className={styles.preview}
          style={{ backgroundImage: `url(${new URL(image)})` }}
          onClick={this.togglePreviewModal(true)}
        />
        {isShown && (
          <div className={styles.modalWrapper}>
            <div className={styles.modalContent}>
              <img src={new URL(image)} />
              <button className={styles.button} onClick={this.togglePreviewModal(false)}>
                ОК
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    ) : null;
  }
}

export default ImagePreview;
