import React from 'react';
import styles from './styles.module.scss';

class Counter extends React.Component {
  state = {
    count: this.props.count || 0,
  };

  updateCount = count => () => {
    if (count < 0) return;
    this.setState({ count });
  };

  render() {
    const { count } = this.state;
    const { inputId, inputName } = this.props;
    return (
      <div className={styles.counterControlWrapper}>
        <div className={styles.counterControlMinus} onClick={this.updateCount(count - 1)} />
        <input className={styles.counter} value={count} type="number" readOnly id={inputId} name={inputName} />
        <div className={styles.counterControlPlus} onClick={this.updateCount(count + 1)} />
      </div>
    );
  }
}

export default Counter;
