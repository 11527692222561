import React from 'react';
import Select from './';

class SeparateSelector extends React.Component {
  state = {
    selectedOption: this.props.options && this.props.options.find(el => el.default),
  };

  componentDidUpdate(prevProps) {
    if (this.props.options !== prevProps.options) {
      const defaultOption = this.props.options && this.props.options.find(el => el.default);
      defaultOption && this.setState({ selectedOption: defaultOption });
    }
  }

  onChange = item => this.setState({ selectedOption: item });

  render() {
    const { id, name, options } = this.props;
    const { selectedOption } = this.state;
    return <Select value={selectedOption} options={options} id={id} name={name} onChange={this.onChange} />;
  }
}

export default SeparateSelector;
