import React from 'react';
import Input from '../input';
import emailMask from '../../../helpers/emailMask';

class EmailMaskedInput extends React.Component {
  state = {
    value: this.props.value || '',
  }

  onChange = (e) => {
    this.props.onChange && this.props.onChange(e);
    this.setState({
      value: e.target.value,
    });
  }

  render() {
    return <>
      <input
        name={this.props.name}
        id={this.props.id}
        hidden
        value={this.state.value}
        readOnly
      />
      <Input
        label={this.props.label}
        placeholder={this.props.placeholder}
        value={this.state.value}
        onChange={this.onChange}
        mask={emailMask}
        error={this.props.error}
      />
    </>;
  }
}

export default EmailMaskedInput;