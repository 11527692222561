import React from 'react';
import styles from './styles.module.scss';
import MaskedInput from 'react-text-mask';
import validation from '../../registration/form/validation';

class PassportInput extends React.Component {
  render() {
    const t = I18n.frontend.components.shared.passport_input;
    return (
      <div className={styles.wrapper}>
        <div className={styles.inputWrapper}>
          <label className={styles.inputLabel}>{t.series}</label>
          <MaskedInput
            placeholder={t.series_placeholder}
            className={`${styles.input} ${styles.inputSerial}`}
            mask={[/\d/, /\d/, /\d/, /\d/]}
            value={this.props.passport_series}
            onChange={this.props.onChange('passport_series')}
            onBlur={e =>
              this.props.validatePassport('passport_series', validation.isPassportSeriestValid)(e.currentTarget.value)
            }
            guide={false}
          />
        </div>
        <div className={styles.inputWrapper}>
          <label className={styles.inputLabel}>{t.number}</label>
          <MaskedInput
            placeholder={t.number_placeholder}
            className={`${styles.input} ${styles.inputNumber}`}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            value={this.props.passport_number}
            onChange={this.props.onChange('passport_number')}
            onBlur={e =>
              this.props.validatePassport('passport_number', validation.isPassportNumberValid)(e.currentTarget.value)
            }
            guide={false}
          />
        </div>
      </div>
    );
  }
}

export default PassportInput;
